export function getOs() {       //获取操作系统
  let name = '';
  if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1) {
    name = "Windows 10";
  } else if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) {
    name = "Windows 8";
  } else if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) {
    name = "Windows 7";
  } else if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) {
    name = "Windows Vista";
  } else if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) {
    name = "Windows XP";
  } else if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) {
    name = "Windows 2000";
  } else if (window.navigator.userAgent.indexOf("Mac") != -1) {
    name = "Mac/iOS";
  } else if (window.navigator.userAgent.indexOf("X11") != -1) {
    name = "UNIX";
  } else if (window.navigator.userAgent.indexOf("Linux") != -1) {
    name = "Linux";
  } else {
    name = "other";
  }
  return name;
}

export function getBrowser() {         //获取浏览器
  var _AgentInfo = {};
  var nAgt = navigator.userAgent;
  var browserName = navigator.appName;
  var fullVersion = '' + parseFloat(navigator.appVersion);
  var majorVersion = parseInt(navigator.appVersion, 10);
  var nameOffset, verOffset, ix;
  if ((verOffset = nAgt.indexOf("Opera")) != -1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf("Version")) != -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  else if ((nAgt.indexOf("Trident")) != -1) {
    if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
      fullVersion = nAgt.substring(verOffset + 5);
    } else {
      fullVersion = '11.0';
    }
    if (fullVersion == 5) {
      fullVersion = "11.0";
    }
    browserName = "IE";
  }
  else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset + 7);
  }
  else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf("Version")) != -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset + 8);
  }
  else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() == browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }
  if ((ix = fullVersion.indexOf(";")) != -1)
    fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(" ")) != -1)
    fullVersion = fullVersion.substring(0, ix);
  majorVersion = parseInt('' + fullVersion, 10);
  if (isNaN(majorVersion)) {
    fullVersion = '' + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }
  _AgentInfo.browserName = browserName;
  _AgentInfo.browserVer = fullVersion;
  return _AgentInfo;
}

export function confirmMobileType() {
  var browser = {
    versions: function () {
      var u = navigator.userAgent, app = navigator.appVersion;
      return {//移动终端浏览器版本信息
        trident: u.indexOf('Trident') > -1, //IE内核
        presto: u.indexOf('Presto') > -1, //opera内核
        webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
        mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/), //是否为移动终端
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
        iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1, //是否为iPhone或者QQHD浏览器
        iPad: u.indexOf('iPad') > -1, //是否iPad
        webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
      };
    }(),
    language: (navigator.browserLanguage || navigator.language).toLowerCase()
  }
  var flag = false, isAndroid, isIos;
  //1.是否mobile，否，肯定不是。
  if (browser.versions.mobile) {
    //2.是否ios或android终端，有一个是
    if (browser.versions.android || browser.versions.ios) {
      flag = true;
      isAndroid = browser.versions.android;
      isIos = browser.versions.ios;
    }
  }
  return { isMobile: flag, isAndroid, isIos }
}