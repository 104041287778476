<template>
  <div class="publish-wrap" v-loading="loading">
    <div class="content_area">
      <div class="backTitle tc">
        <span class="icon-wrap" @click="goToBack">
          <i class="el-icon-back"></i>
        </span>
        <span v-if="inquiryId">{{ $t("editInquiryinfo") }}</span>
        <span v-else>{{ $t("whiteInquiryInfo") }}</span>
      </div>
      <div class="inquiryInfo">
        <div class="info-title">{{ $t("goodsMsg") }}</div>
        <div class="info-content">
          <el-form
            size="small"
            :model="inquiryForm"
            :rules="inquiryRules"
            ref="inquiryForm"
            class="demo-ruleForm"
          >
            <div class="cityCountry mb20">
              <div class="label">
                {{ $t("departure") }}
                <span class="heart"></span>
              </div>
              <el-form-item prop="start_city" class="mr12">
                <CityCompleteWithCountry
                  @countryChange="getStartCountryId"
                  style="width: 200px"
                  :placeTitle="LOCALE === 'en' ? 'City' : '城市'"
                  :locale="LOCALE"
                  v-model="inquiryForm.start_city"
                ></CityCompleteWithCountry>
              </el-form-item>
              <el-form-item prop="start_country">
                <CountrySelect
                  style="width: 200px"
                  v-model="inquiryForm.start_country"
                  size="small"
                  autocomplete="off"
                  :placeTitle="LOCALE === 'en' ? 'Country' : '国家'"
                  :locale="LOCALE"
                  :canClear="false"
                ></CountrySelect>
              </el-form-item>
              <div class="label ml60">
                {{ $t("destination") }}
                <span class="heart"></span>
              </div>
              <el-form-item prop="end_city" class="mr12">
                <CityCompleteWithCountry
                  @countryChange="getEndCountryId"
                  style="width: 200px"
                  :placeTitle="LOCALE === 'en' ? 'City' : '城市'"
                  :locale="LOCALE"
                  v-model="inquiryForm.end_city"
                ></CityCompleteWithCountry>
              </el-form-item>
              <el-form-item prop="end_country">
                <CountrySelect
                  style="width: 200px"
                  v-model="inquiryForm.end_country"
                  size="small"
                  autocomplete="off"
                  :placeTitle="LOCALE === 'en' ? 'Country' : '国家'"
                  :locale="LOCALE"
                  :canClear="false"
                ></CountrySelect>
              </el-form-item>
            </div>
            <div class="product mb20">
              <div class="label" style="width:92px">
                {{ $t("commodity") }}
                <span class="heart"></span>
              </div>
              <el-form-item prop="name">
                <el-input
                  style="width: 300px"
                  v-model="inquiryForm.name"
                  :placeholder="$t('pleacenamecart')"
                ></el-input>
              </el-form-item>
              <div class="label ml60" style="width: 130px;">
                {{ $t("shippingMode") }}
                <span class="heart"></span>
              </div>
              <el-form-item prop="transport" class="mr12">
                <el-select
                  style="width: 200px"
                  v-model="inquiryForm.transport"
                  :placeholder="$t('cargoTiptransportMethed')"
                >
                  <el-option
                    v-for="item in transportOptions"
                    :key="item.value"
                    :label="item.label_en | priorFormat(item.label_zh, LOCALE)"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                prop="boxStyle"
                v-if="
                  this.inquiryForm.transport === 2 ||
                    this.inquiryForm.transport === 3 ||
                    this.inquiryForm.transport === 4
                "
              >
                <el-select style="width: 200px" v-model="inquiryForm.boxStyle">
                  <el-option
                    v-for="item in boxOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div class="package mb20">
              <div class="label">
                {{ $t("paceingtype") }}
                <span class="heart"></span>
              </div>
              <el-form-item prop="package">
                <el-select
                  style="width: 412px"
                  v-model="inquiryForm.package"
                  :placeholder="$t('pleaseselectpacking')"
                >
                  <el-option
                    v-for="item in packageOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div class="quantity mb30">
              <div class="label">
                {{ $t("number ") }}
                <span class="heart"></span>
              </div>
              <el-form-item prop="quantity">
                <el-input
                  type="number"
                  style="width: 200px"
                  v-model="inquiryForm.quantity"
                  :placeholder="$t('pleaseWriteNumber')"
                ></el-input>
              </el-form-item>
              <div class="optional">（{{ $t("optional") }}）：</div>

              <el-form-item prop="numone">
                <el-input
                  type="number"
                  :placeholder="$t('numberone')"
                  style="width: 108px"
                  v-model="inquiryForm.numone"
                ></el-input>
              </el-form-item>
              <div class="num1 star">*</div>
              <el-form-item prop="numoneunit">
                <el-select
                  style="width: 108px"
                  v-model="inquiryForm.numoneunit"
                >
                  <el-option
                    v-for="item in numUnitOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <div class="numadd">+</div>
              <el-form-item prop="numtwo">
                <el-input
                  type="number"
                  :placeholder="$t('numbertwo')"
                  style="width: 108px"
                  v-model="inquiryForm.numtwo"
                ></el-input>
              </el-form-item>
              <div class="num2 star">*</div>
              <el-form-item prop="numtwounit">
                <el-select
                  style="width: 108px"
                  v-model="inquiryForm.numtwounit"
                >
                  <el-option
                    v-for="item in numUnitOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div class="weight mb20">
              <div class="label">
                {{ $t("totalWeight") }}
                <span class="heart"></span>
              </div>
              <el-form-item prop="weight" class="mr12">
                <el-input
                  type="number"
                  style="width: 200px"
                  v-model="inquiryForm.weight"
                  :placeholder="$t('pleasewhitenumber')"
                ></el-input>
              </el-form-item>
              <el-form-item prop="weightUnit">
                <el-select
                  style="width: 200px"
                  v-model="inquiryForm.weightUnit"
                >
                  <el-option
                    v-for="item in weightOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div class="volume mb20">
              <div class="label">{{ $t("volume") }}</div>
              <el-form-item prop="volume">
                <el-input
                  type="number"
                  style="width: 200px"
                  v-model="inquiryForm.volume"
                  :placeholder="$t('pleaseenter')"
                >
                  <span slot="suffix">{{ $t("cube") }}</span>
                </el-input>
              </el-form-item>
              <div class="optional">（{{ $t("optional") }}）：</div>
              <el-form-item prop="long">
                <el-input
                  :placeholder="$t('length')"
                  style="width: 108px"
                  v-model="inquiryForm.long"
                ></el-input>
              </el-form-item>
              <div class="star">*</div>
              <el-form-item prop="width">
                <el-input
                  :placeholder="$t('width')"
                  style="width: 108px"
                  v-model="inquiryForm.width"
                ></el-input>
              </el-form-item>
              <div class="star">*</div>
              <el-form-item prop="high">
                <el-input
                  :placeholder="$t('height')"
                  style="width: 108px"
                  v-model="inquiryForm.high"
                ></el-input>
              </el-form-item>
              <el-form-item prop="volumeUnit" class="ml24">
                <el-select
                  style="width: 108px"
                  v-model="inquiryForm.volumeUnit"
                >
                  <el-option
                    v-for="item in volumeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div class="tradeTerms mb20">
              <div class="label" :class="{ en_maoyi_width: IS_EN }">
                {{ $t("Incoterms") }}
                <span class="heart"></span>
              </div>
              <el-form-item prop="tradeTerms">
                <el-select
                  style="width: 200px"
                  v-model="inquiryForm.tradeTerms"
                >
                  <el-option
                    v-for="item in tradeTermsOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                prop="address"
                class="ml12"
                v-if="
                  inquiryForm.tradeTerms === 97 ||
                    inquiryForm.tradeTerms === 109 ||
                    inquiryForm.tradeTerms === 108 ||
                    inquiryForm.tradeTerms === 110 ||
                    inquiryForm.tradeTerms === 105 ||
                    inquiryForm.tradeTerms === 106 ||
                    inquiryForm.tradeTerms === 107
                "
              >
                <el-input
                  :placeholder="$t('assresspickup')"
                  style="width: 586px"
                  v-model="inquiryForm.address"
                ></el-input>
              </el-form-item>
            </div>
            <div class="dangerous mb20">
              <div class="label"></div>
              <el-form-item prop="dangerous" class="mr12">
                <el-checkbox v-model="inquiryForm.dangerous">{{
                  $t("dangerousGoods")
                }}</el-checkbox>
              </el-form-item>
              <el-form-item prop="dangerousNumber" class="mr12">
                <el-input
                  :disabled="!inquiryForm.dangerous"
                  :placeholder="$t('serialNumber')"
                  style="width: 108px"
                  v-model="inquiryForm.dangerousNumber"
                ></el-input>
              </el-form-item>
              <el-form-item prop="temperature" class="mr12">
                <el-checkbox v-model="inquiryForm.temperature">{{
                  $t("temperatureGoods")
                }}</el-checkbox>
              </el-form-item>
              <el-form-item prop="temperatureSpecif" class="mr12">
                <el-input
                  :disabled="!inquiryForm.temperature"
                  :placeholder="$t('specifictemperature')"
                  style="width: 108px"
                  v-model="inquiryForm.temperatureSpecif"
                >
                  <span slot="suffix">℃</span>
                </el-input>
              </el-form-item>
              <el-form-item prop="stacked" class="mr12">
                <el-checkbox v-model="inquiryForm.stacked">{{
                  $t("nonStackable")
                }}</el-checkbox>
              </el-form-item>
            </div>
            <div class="remark">
              <div class="label">{{ $t("notes") }}</div>
              <el-form-item prop="remark">
                <el-input
                  class="showwordlimit"
                  type="textarea"
                  :autosize="{ minRows: 7 }"
                  :placeholder="$t('cargosremarksPlaceholder')"
                  style="width: 412px"
                  v-model="inquiryForm.remark"
                  maxlength="1000"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <div class="label ml60">{{ $t("file") }}</div>
              <div>
                <div class="upload-btn">
                  <el-button
                    v-if="uploadLength.length >= 3"
                    disabled
                    class="uploadInformation"
                    size="small"
                    type="primary"
                    icon="el-icon-upload2"
                    >{{ $t("uploadFile") }}</el-button
                  >
                  <fileUpload
                    v-else
                    ref="uploadComponents"
                    :autoUpload="fileUploadOptions.autoUpload"
                    :accept="fileUploadOptions.accept"
                    @uploadCompleteOSS="uploadFieCallback"
                    @selectFile="selectFileListUpload"
                  >
                    <el-button
                      :disabled="uploadLength.length >= 3"
                      class="uploadInformation"
                      size="small"
                      type="primary"
                      icon="el-icon-upload2"
                      >{{ $t("uploadFile") }}</el-button
                    >
                  </fileUpload>
                </div>
                <div class="annex">
                  <div
                    class="item"
                    v-for="(item, index) in inquiryForm.uploadList"
                    :key="index"
                  >
                    <template v-if="item.deleted === 0">
                      <el-image
                        style="width: 25px; height: 25px;margin-right:15px;"
                        :src="item.file_type | enclosureImgbyTypeFormat"
                        fit="cover"
                      ></el-image>
                      <div class="filename textOverflow">
                        {{ item.file_name }}
                      </div>
                      <i
                        @click="deleteFile(item)"
                        style="text-align: center; width: 25px; line-height: 25px"
                        class="el-icon-close"
                      ></i>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </el-form>
        </div>
      </div>
      <div class="accountInfo">
        <div class="info-title">{{ $t("BasicInformation") }}</div>
        <div class="info-content">
          <el-form
            size="small"
            :model="accountForm"
            :rules="accountRules"
            ref="accountForm"
            class="demo-ruleForm"
          >
            <div class="company mb20">
              <div class="label">{{ $t("contact") }}</div>
              <el-form-item prop="account">
                <el-input
                  :placeholder="$t('entercontactname')"
                  style="width: 412px"
                  v-model="accountForm.account"
                ></el-input>
              </el-form-item>
              <div class="label ml60">
                {{ $t("Company_n") }}
                <span class="heart"></span>
              </div>
              <el-form-item prop="company">
                <el-input
                  :placeholder="$t('Enter_company_name')"
                  style="width: 412px"
                  v-model="accountForm.company"
                ></el-input>
              </el-form-item>
            </div>
            <div class="contact">
              <div class="label">{{ $t("phone") }}</div>
              <el-form-item prop="phone">
                <el-input
                  v-model="accountForm.phone"
                  type="number"
                  style="width: 412px"
                  class="input-with-select"
                  :placeholder="$t('enterTelephone')"
                >
                  <el-select
                    v-model="accountForm.areaCode"
                    slot="prepend"
                    popper-class="registerAreaCodeList"
                    filterable
                  >
                    <!-- :filter-method="areaCodeFilter" -->
                    <el-option
                      v-for="(item, index) in areaCodeList"
                      :key="item.area_code + index + ''"
                      :value="item.area_code"
                    >
                      <!-- :title="item.country | areacodeTitleFormat(LOCALE)" -->
                      <p class="area_code">{{ item.area_code }}</p>
                      <!-- <p class="country textOverflow">
                        {{ item.country | areacodeAddressFormat(LOCALE) }}
                      </p>-->
                    </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
              <div class="label ml60">
                {{ $t("email") }}
                <span class="heart"></span>
              </div>
              <el-form-item prop="email">
                <el-input
                  :placeholder="$t('enteremailaddress')"
                  style="width: 412px"
                  v-model="accountForm.email"
                ></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
      <div class="btns" v-if="inquiryId">
        <el-button
          @click="goToBack"
          class="mr30 cancel"
          style="width: 336px"
          type="info"
          plain
          >{{ $t("cancel") }}</el-button
        >
        <el-button
          :loading="btnLoading"
          @click="publish(2, 'inquiryForm', 'accountForm')"
          class="mr30"
          :class="{ confirm: PJSource == 7 }"
          style="width: 336px"
          type="primary"
          >{{ $t("save") }}</el-button
        >
      </div>
      <div class="btns" v-else>
        <el-button
          :disabled="btnLoading"
          :loading="draftLoading"
          @click="publish(0, 'inquiryForm', 'accountForm')"
          class="mr30 "
          :class="{ draft: PJSource == 7 }"
          style="width: 336px"
          plain
          >{{ $t("savetodraft") }}</el-button
        >
        <el-button
          :disabled="draftLoading || btnLoading"
          @click="goToBack"
          class="mr30 cancel"
          style="width: 336px"
          type="info"
          plain
          >{{ $t("cancel") }}</el-button
        >
        <el-button
          :disabled="draftLoading"
          :loading="btnLoading"
          @click="publish(2, 'inquiryForm', 'accountForm')"
          class="mr30"
          :class="{ confirm: PJSource == 7 }"
          style="width: 336px"
          type="primary"
          >{{ $t("confirmsubmission") }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import CityCompleteWithCountry from "@/baseComponents/CityCompleteWithCountry";
import CountrySelect from "@/baseComponents/CountrySelect";
import { areaCodeList } from "~/baseUtils/AddressConfig";
import fileUpload from "@/baseComponents/fileUpload";
import { priorFormat } from "@/basePlugins/filters";
import {
  getFilesName,
  getFileType,
  getenclosureType,
} from "@/basePlugins/filters";
import log from "@/basePlugins/log";

export default {
  metaInfo() {
    let title = null;
    if (this.PJSource === 27) {
      title = "厦门货代协会--一键询盘";
    } else {
      title = "一键询盘"
    }
    return {
      title
    };
  },
  name: "publishInquiry",
  components: {
    CityCompleteWithCountry,
    CountrySelect,
    fileUpload,
  },
  data() {
    return {
      inquiryDetail: {},
      loading: false,
      inquiryUserId: null,
      inquiryCompanyId: null,
      inquiryUserEmail: null,
      inquiryId: null,
      inquiryFilloutType: null,
      areaCodeList: areaCodeList,
      areaCodeListCopy: [].concat(areaCodeList),
      fileUploadOptions: {
        accept:
          ".jpg,.jpeg,.png,.bmp,.pdf,.doc,.docx,.xls,.xlsx,.txt,.rar,.zip",
      },
      inquiryForm: {
        start_city: null, //起运地城市
        start_country: null, //起运地国家
        end_city: null, //目的地城市
        end_country: null, //目的地国家
        name: null, //品名
        transport: null, //运输方式
        boxStyle: null, //整箱拼箱
        package: null, //包装方式
        quantity: null, //数量
        numone: null, //数量一
        numoneunit: 77, //数量一单位
        numtwo: null, //数量二
        numtwounit: 77, //数量二单位
        weight: null, //重量
        weightUnit: 1, //重量单位
        volume: null, //体积
        long: null, //长
        width: null, //宽
        high: null, //高
        volumeUnit: 1, //体积单位
        tradeTerms: null, //贸易条款
        address: null, //提货地址
        dangerous: false, //是否危险品
        dangerousNumber: null, //危险品编号
        temperature: false, //温度商品
        temperatureSpecif: null, //具体温度
        stacked: false, //不可堆叠
        remark: null, //备注
        uploadList: [], //上传资料
      },
      inquiryRules: {
        start_city: {
          required: true,
          message: " ",
          trigger: "blur",
        },
        start_country: {
          required: true,
          message: " ",
          trigger: "blur",
        },
        end_city: {
          required: true,
          message: " ",
          trigger: "blur",
        },
        end_country: {
          required: true,
          message: " ",
          trigger: "blur",
        },
        name: {
          required: true,
          message: " ",
          trigger: "blur",
        },
        transport: {
          required: true,
          message: " ",
          trigger: "blur",
        },
        boxStyle: {
          validator: async (rule, value, callback) => {},
          trigger: "blur",
        },
        package: {
          required: true,
          message: " ",
          trigger: "blur",
        },
        quantity: {
          required: true,
          message: " ",
          trigger: "blur",
        },
        numone: {
          validator: async (rule, value, callback) => {
            if (this.inquiryForm.numtwo) {
              if (!value) {
                callback(new Error(" "));
              } else {
                if (
                  Number(value) + Number(this.inquiryForm.numtwo) ===
                  Number(this.inquiryForm.quantity)
                ) {
                  callback();
                } else {
                  callback(new Error(" "));
                }
              }
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        numoneunit: {
          validator: async (rule, value, callback) => {
            if (this.inquiryForm.numone) {
              if (!value) {
                callback(new Error(" "));
              } else {
                callback();
              }
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        numtwo: {
          validator: async (rule, value, callback) => {
            if (this.inquiryForm.numone) {
              if (!value) {
                callback(new Error(" "));
              } else {
                if (
                  Number(value) + Number(this.inquiryForm.numone) ===
                  Number(this.inquiryForm.quantity)
                ) {
                  callback();
                } else {
                  callback(new Error(this.$t("oneaddtwonot")));
                }
              }
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        numtwounit: {
          validator: async (rule, value, callback) => {
            if (this.inquiryForm.numtwo) {
              if (!value) {
                callback(new Error(" "));
              } else {
                callback();
              }
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        weight: {
          required: true,
          message: " ",
          trigger: "blur",
        },
        weightUnit: {
          validator: async (rule, value, callback) => {},
          trigger: "blur",
        },
        volume: {
          validator: async (rule, value, callback) => {
            if (
              this.inquiryForm.transport === 1 ||
              this.inquiryForm.transport === 3 ||
              this.inquiryForm.boxStyle === 4
            ) {
              if (!value) {
                callback(new Error(" "));
              } else {
                callback();
              }
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        long: {
          validator: async (rule, value, callback) => {},
          trigger: "blur",
        },
        width: {
          validator: async (rule, value, callback) => {},
          trigger: "blur",
        },
        high: {
          validator: async (rule, value, callback) => {},
          trigger: "blur",
        },
        volumeUnit: {
          validator: async (rule, value, callback) => {},
          trigger: "blur",
        },
        tradeTerms: {
          required: true,
          message: " ",
          trigger: "blur",
        },
        address: {
          validator: async (rule, value, callback) => {
            let arr = [97, 109, 108, 110, 105, 106, 107];
            if (arr.includes(this.inquiryForm.tradeTerms)) {
              if (!value) {
                callback(new Error(" "));
              } else {
                callback();
              }
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        dangerous: {
          validator: async (rule, value, callback) => {},
          trigger: "blur",
        },
        dangerousNumber: {
          validator: async (rule, value, callback) => {
            if (this.inquiryForm.dangerous) {
              if (!value) {
                callback(new Error(" "));
              } else {
                callback();
              }
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        temperature: {
          validator: async (rule, value, callback) => {},
          trigger: "blur",
        },
        temperatureSpecif: {
          validator: async (rule, value, callback) => {
            if (this.inquiryForm.temperature) {
              if (!value) {
                callback(new Error(" "));
              } else {
                callback();
              }
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        stacked: {
          validator: async (rule, value, callback) => {},
          trigger: "blur",
        },
        email: {
          validator: async (rule, value, callback) => {},
          trigger: "blur",
        },
        remark: {
          validator: async (rule, value, callback) => {},
          trigger: "blur",
        },
      },
      accountForm: {
        account: null, //联系人
        company: null, //公司名
        areaCode: null, //区号
        phone: null, //手机号
        email: null, //邮箱
      },
      accountRules: {
        account: {},
        company: {
          required: true,
          message: " ",
          trigger: "blur",
        },
        phone: {},
        email: {
          required: true,
          message: " ",
          trigger: "blur",
        },
      },
      transportOptions: [
        {
          value: 2,
          label_zh: "海运",
          label_en: "Sea",
        },
        {
          value: 1,
          label_zh: "空运",
          label_en: "Air",
        },
        {
          value: 3,
          label_zh: "铁路",
          label_en: "Rail",
        },
        {
          value: 4,
          label_zh: "多式联运",
          label_en: "Multimodal transport",
        },
        {
          value: 5,
          label_zh: "陆运",
          label_en: "Truck",
        },
        {
          value: 6,
          label_zh: "国际快递",
          label_en: "International Express",
        },
      ],
      boxOptions: [
        {
          value: 1,
          label: this.$t("FCL"),
        },
        {
          value: 2,
          label: this.$t("LCL"),
        },
      ],
      packageOptions: [],
      numUnitOptions: [
        {
          value: 77,
          label: "20GP",
        },
        {
          value: 78,
          label: "40GP",
        },
        {
          value: 79,
          label: "40HQ",
        },
        {
          value: 308,
          label: "40OT",
        },
        {
          value: 309,
          label: "40FR",
        },
        {
          value: 313,
          label: "20RF",
        },
        {
          value: 314,
          label: "40RF",
        },
        {
          value: 310,
          label: "40HT",
        },
        {
          value: 311,
          label: "40TK",
        },
      ],
      weightOptions: [
        {
          value: 1,
          label: this.$t("KG"),
        },
        {
          value: 2,
          label: this.$t("Ton"),
        },
      ],
      volumeOptions: [
        {
          value: 1,
          label: this.$t("M"),
        },
        {
          value: 2,
          label: this.$t("CM"),
        },
      ],
      tradeTermsOptions: [
        {
          value: 97,
          label: "EXW",
        },
        {
          value: 98,
          label: "FCA",
        },
        {
          value: 99,
          label: "FAS",
        },
        {
          value: 100,
          label: "FOB",
        },
        {
          value: 101,
          label: "CFR",
        },
        {
          value: 102,
          label: "CIF",
        },
        {
          value: 103,
          label: "CPT",
        },
        {
          value: 104,
          label: "CIP",
        },
        {
          value: 105,
          label: "DAF",
        },
        {
          value: 106,
          label: "DES",
        },
        {
          value: 107,
          label: "DEQ",
        },
        {
          value: 108,
          label: "DDU",
        },
        {
          value: 109,
          label: "DDP",
        },
        {
          value: 110,
          label: "DAP",
        },
      ],
      selectUploadFile: {},
      btnLoading: false,
      draftLoading: false,
    };
  },
  created() {
    let routerParams = this._decode(this.$route.query.parameter);
    if (routerParams.company_id) {
      this.inquiryCompanyId = routerParams.company_id;
    }
    if (routerParams.user_id) {
      this.inquiryUserId = routerParams.user_id;
    }
    if (routerParams.email) {
      this.inquiryUserEmail = routerParams.email;
    }
    if (routerParams.inquiry_id) {
      this.inquiryId = routerParams.inquiry_id;
    }
    if (routerParams.fillout_type) {
      this.inquiryFilloutType = routerParams.fillout_type;
    }
  },
  computed: {
    packageChange() {
      const { transport, boxStyle } = this.inquiryForm;
      return {
        transport,
        boxStyle,
      };
    },
    uploadLength() {
      return this.inquiryForm.uploadList.filter((item) => item.deleted === 0);
    },
  },
  watch: {
    packageChange() {
      //海运整箱
      if (this.inquiryForm.transport === 2 && this.inquiryForm.boxStyle === 1) {
        this.packageOptions = [
          {
            value: 77,
            label: "20GP",
          },
          {
            value: 78,
            label: "40GP",
          },
          {
            value: 79,
            label: "40HQ",
          },
          {
            value: 80,
            label: "45HQ",
          },
          {
            value: 308,
            label: "40OT",
          },
          {
            value: 309,
            label: "40FR",
          },
          {
            value: 310,
            label: "40HT",
          },
          {
            value: 311,
            label: "40TK",
          },
          {
            value: 312,
            label: "40HG",
          },
          {
            value: 313,
            label: "20RF",
          },
          {
            value: 314,
            label: "40RF",
          },
        ];
      }
      //海运拼箱
      if (this.inquiryForm.transport === 2 && this.inquiryForm.boxStyle === 2) {
        this.packageOptions = [
          {
            value: 75,
            label: this.$t("tuopan"),
          },
          {
            value: 71,
            label: this.$t("zhixiang"),
          },
          {
            value: 72,
            label: this.$t("yuantong"),
          },
          {
            value: 69,
            label: this.$t("daizhuang"),
          },
          {
            value: 74,
            label: this.$t("pantiaoxiang"),
          },
          {
            value: 73,
            label: this.$t("kunzhuang"),
          },
          {
            value: 70,
            label: this.$t("nopackge"),
          },
          {
            value: 315,
            label: this.$t("Others"),
          },
        ];
      }
      //空运
      if (this.inquiryForm.transport === 1) {
        this.packageOptions = [
          {
            value: 75,
            label: this.$t("tuopan"),
          },
          {
            value: 71,
            label: this.$t("zhixiang"),
          },
          {
            value: 72,
            label: this.$t("yuantong"),
          },
          {
            value: 69,
            label: this.$t("daizhuang"),
          },
          {
            value: 74,
            label: this.$t("pantiaoxiang"),
          },
          {
            value: 73,
            label: this.$t("kunzhuang"),
          },
          {
            value: 70,
            label: this.$t("nopackge"),
          },
          {
            value: 315,
            label: this.$t("Others"),
          },
        ];
      }
      //陆运
      if (this.inquiryForm.transport === 5) {
        this.packageOptions = [
          {
            value: 77,
            label: "20GP",
          },
          {
            value: 78,
            label: "40GP",
          },
          {
            value: 79,
            label: "40HQ",
          },
          {
            value: 80,
            label: "45HQ",
          },
          {
            value: 308,
            label: "40OT",
          },
          {
            value: 309,
            label: "40FR",
          },
          {
            value: 310,
            label: "40HT",
          },
          {
            value: 311,
            label: "40TK",
          },
          {
            value: 312,
            label: "40HG",
          },
          {
            value: 313,
            label: "20RF",
          },
          {
            value: 314,
            label: "40RF",
          },
          {
            value: 75,
            label: this.$t("tuopan"),
          },
          {
            value: 71,
            label: this.$t("zhixiang"),
          },
          {
            value: 72,
            label: this.$t("yuantong"),
          },
          {
            value: 69,
            label: this.$t("daizhuang"),
          },
          {
            value: 74,
            label: this.$t("pantiaoxiang"),
          },
          {
            value: 73,
            label: this.$t("kunzhuang"),
          },
          {
            value: 70,
            label: this.$t("nopackge"),
          },
          {
            value: 315,
            label: this.$t("Others"),
          },
        ];
      }
      //铁路整箱
      if (this.inquiryForm.transport === 3 && this.inquiryForm.boxStyle === 1) {
        this.packageOptions = [
          {
            value: 77,
            label: "20GP",
          },
          {
            value: 78,
            label: "40GP",
          },
          {
            value: 79,
            label: "40HQ",
          },
          {
            value: 80,
            label: "45HQ",
          },
          {
            value: 308,
            label: "40OT",
          },
          {
            value: 309,
            label: "40FR",
          },
          {
            value: 310,
            label: "40HT",
          },
          {
            value: 311,
            label: "40TK",
          },
          {
            value: 312,
            label: "40HG",
          },
          {
            value: 313,
            label: "20RF",
          },
          {
            value: 314,
            label: "40RF",
          },
        ];
      }
      //铁路拼箱
      if (this.inquiryForm.transport === 3 && this.inquiryForm.boxStyle === 2) {
        this.packageOptions = [
          {
            value: 75,
            label: this.$t("tuopan"),
          },
          {
            value: 71,
            label: this.$t("zhixiang"),
          },
          {
            value: 72,
            label: this.$t("yuantong"),
          },
          {
            value: 69,
            label: this.$t("daizhuang"),
          },
          {
            value: 74,
            label: this.$t("pantiaoxiang"),
          },
          {
            value: 73,
            label: this.$t("kunzhuang"),
          },
          {
            value: 70,
            label: this.$t("nopackge"),
          },
          {
            value: 315,
            label: this.$t("Others"),
          },
        ];
      }
      //国际快递
      if (this.inquiryForm.transport === 6) {
        this.packageOptions = [
          {
            value: 75,
            label: this.$t("tuopan"),
          },
          {
            value: 71,
            label: this.$t("zhixiang"),
          },
          {
            value: 72,
            label: this.$t("yuantong"),
          },
          {
            value: 69,
            label: this.$t("daizhuang"),
          },
          {
            value: 74,
            label: this.$t("pantiaoxiang"),
          },
          {
            value: 73,
            label: this.$t("kunzhuang"),
          },
          {
            value: 70,
            label: this.$t("nopackge"),
          },
          {
            value: 315,
            label: this.$t("Others"),
          },
        ];
      }
      //多式联运整箱
      if (this.inquiryForm.transport === 4 && this.inquiryForm.boxStyle === 1) {
        this.packageOptions = [
          {
            value: 77,
            label: "20GP",
          },
          {
            value: 78,
            label: "40GP",
          },
          {
            value: 79,
            label: "40HQ",
          },
          {
            value: 80,
            label: "45HQ",
          },
          {
            value: 308,
            label: "40OT",
          },
          {
            value: 309,
            label: "40FR",
          },
          {
            value: 310,
            label: "40HT",
          },
          {
            value: 311,
            label: "40TK",
          },
          {
            value: 312,
            label: "40HG",
          },
          {
            value: 313,
            label: "20RF",
          },
          {
            value: 314,
            label: "40RF",
          },
        ];
      }
      //多式联运拼箱
      if (this.inquiryForm.transport === 4 && this.inquiryForm.boxStyle === 2) {
        this.packageOptions = [
          {
            value: 75,
            label: this.$t("tuopan"),
          },
          {
            value: 71,
            label: this.$t("zhixiang"),
          },
          {
            value: 72,
            label: this.$t("yuantong"),
          },
          {
            value: 69,
            label: this.$t("daizhuang"),
          },
          {
            value: 74,
            label: this.$t("pantiaoxiang"),
          },
          {
            value: 73,
            label: this.$t("kunzhuang"),
          },
          {
            value: 70,
            label: this.$t("nopackge"),
          },
          {
            value: 315,
            label: this.$t("Others"),
          },
        ];
      }
    },
    "inquiryForm.transport"(e) {
      if (e === 2 || e === 3 || e === 4) {
        if (this.inquiryId) {
        } else {
          this.inquiryForm.boxStyle = 1;
        }
      } else {
        this.inquiryForm.boxStyle = null;
      }
    },
    "inquiryForm.dangerous"(e) {
      if (!e) {
        this.inquiryForm.dangerousNumber = "";
      }
    },
    "inquiryForm.temperature"(e) {
      if (!e) {
        this.inquiryForm.temperatureSpecif = "";
      }
    },
  },
  mounted() {
    if (this.IS_EN) {
      document.title = "Quick Inquriy";
    } else {
      document.title = "一键询盘";
    }

    if (this.inquiryId) {
      this.getDetail();
    } else {
      this.accountForm.account = priorFormat(
        this.USER_INFO.name_en,
        this.USER_INFO.name_zh,
        this.LOCALE
      );
      this.accountForm.company = priorFormat(
        this.USER_INFO.company_name_en,
        this.USER_INFO.company_name_zh,
        this.LOCALE
      );
      this.accountForm.areaCode = this.USER_INFO.area_code || "+86";
      this.accountForm.phone = this.USER_INFO.mobile;
      this.accountForm.email = this.USER_INFO.email;
    }
  },
  methods: {
    async getDetail() {
      this.loading = true;
      let params = {};
      params.inquiry_shipper_id = this.inquiryId;
      params.fillout_type = 1;
      let res = await this.$store.dispatch(
        "baseStore/inquiry_inquiryDetail",
        params
      );
      console.log(res);
      if (res.success) {
        this.loading = false;
        let inquiryDetail = res.data;
        this.inquiryDetail = res.data;
        this.inquiryForm.start_city = priorFormat(
          inquiryDetail.from_region_en,
          inquiryDetail.from_region_zh,
          this.LOCALE
        );
        this.inquiryForm.start_country = inquiryDetail.from_country;
        this.inquiryForm.end_city = priorFormat(
          inquiryDetail.arrival_region_en,
          inquiryDetail.arrival_region_zh,
          this.LOCALE
        );
        this.inquiryForm.end_country = inquiryDetail.arrival_country;
        this.inquiryForm.name = inquiryDetail.cargo_name;
        this.inquiryForm.transport = inquiryDetail.shipping_mode;
        this.inquiryForm.boxStyle = inquiryDetail.spell_type;
        this.inquiryForm.package = inquiryDetail.pack_type;
        this.inquiryForm.quantity = inquiryDetail.amount;
        this.inquiryForm.numone = inquiryDetail.num1;
        this.inquiryForm.numoneunit = inquiryDetail.num1_unit;
        this.inquiryForm.numtwo = inquiryDetail.num2;
        this.inquiryForm.numtwounit = inquiryDetail.num2_unit;
        this.inquiryForm.weight = inquiryDetail.weight;
        this.inquiryForm.weightUnit = inquiryDetail.weight_unit;
        this.inquiryForm.volume = inquiryDetail.volume;
        this.inquiryForm.long = inquiryDetail.length;
        this.inquiryForm.width = inquiryDetail.width;
        this.inquiryForm.high = inquiryDetail.height;
        this.inquiryForm.volumeUnit = inquiryDetail.dimension_unit;
        this.inquiryForm.tradeTerms = inquiryDetail.incoterms;
        this.inquiryForm.address = inquiryDetail.address;
        this.inquiryForm.dangerous = inquiryDetail.is_hazardous ? true : false;
        this.inquiryForm.dangerousNumber =
          inquiryDetail.hazardous_info.un_nunber;
        this.inquiryForm.temperature = inquiryDetail.is_temperature
          ? true
          : false;
        this.inquiryForm.temperatureSpecif =
          inquiryDetail.temperature_info.range;
        this.inquiryForm.stacked = inquiryDetail.is_stackable ? false : true;
        this.inquiryForm.remark = inquiryDetail.remarks;
        if (inquiryDetail.attachment_list) {
          this.inquiryForm.uploadList = inquiryDetail.attachment_list;
        }
        this.accountForm.account = inquiryDetail.name;
        this.accountForm.company = inquiryDetail.company;
        this.accountForm.phone = inquiryDetail.phone;
        this.accountForm.areaCode = inquiryDetail.area_code;
        this.accountForm.email = inquiryDetail.email;
      }
      console.log(this.inquiryForm.boxStyle);
    },
    getStartCountryId(e) {
      this.inquiryForm.start_country = e;
    },
    getEndCountryId(e) {
      this.inquiryForm.end_country = e;
    },
    goToBack() {
      this.$router.go(-1);
    },
    publish(value, formName, accountName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$refs[accountName].validate((valid) => {
            if (valid) {
              this.saveFn(value);
            } else {
              return false;
            }
          });
        } else {
          return false;
        }
      });
    },
    async saveFn(value) {
      if (value === 0) {
        this.draftLoading = true;
      } else if (value === 2) {
        this.btnLoading = true;
      }
      let params = {};
      params.company = this.accountForm.company;
      params.name = this.accountForm.account;
      params.phone = this.accountForm.phone;
      params.email = this.accountForm.email;
      params.area_code = this.accountForm.areaCode;
      params.cargo_name = this.inquiryForm.name;
      params.from_country = this.inquiryForm.start_country;
      params.from_city_name = this.inquiryForm.start_city;
      params.arrival_country = this.inquiryForm.end_country;
      params.arrival_city_name = this.inquiryForm.end_city;
      params.amount = this.inquiryForm.quantity;
      params.num1 = this.inquiryForm.numone;
      params.num1_unit = this.inquiryForm.numoneunit;
      params.num2 = this.inquiryForm.numtwo;
      params.num2_unit = this.inquiryForm.numtwounit;
      params.weight = this.inquiryForm.weight;
      params.weight_unit = this.inquiryForm.weightUnit;
      params.volume = this.inquiryForm.volume;
      params.volume_unit = 1;
      params.length = this.inquiryForm.long;
      params.width = this.inquiryForm.width;
      params.height = this.inquiryForm.high;
      params.dimension_unit = this.inquiryForm.volumeUnit;
      params.shipping_mode = this.inquiryForm.transport;
      params.spell_type = this.inquiryForm.boxStyle;
      params.incoterms = this.inquiryForm.tradeTerms;
      params.is_hazardous = this.inquiryForm.dangerous ? 1 : 0;
      params.un_nunber = this.inquiryForm.dangerousNumber;
      params.is_temperature = this.inquiryForm.temperature ? 1 : 0;
      params.range = this.inquiryForm.temperatureSpecif;
      params.is_stackable = this.inquiryForm.stacked ? 0 : 1;
      params.remarks = this.inquiryForm.remark;
      if (this.inquiryForm.uploadList.length) {
        params.attachment_data = JSON.stringify(this.inquiryForm.uploadList);
      }
      params.address = this.inquiryForm.address;
      params.pack_type = this.inquiryForm.package;
      params.creator_email = this.USER_INFO.email;
      params._NOCLEAR = true;
      if (this.inquiryId) {
        //编辑
        params.temperature_id = this.inquiryDetail.temperature_id;
        params.inquiry_id = this.inquiryId;
        let res = await this.$store.dispatch(
          "baseStore/inquiryEditDetail",
          params
        );
        if (res.success) {
          if (value === 0) {
            this.draftLoading = false;
          } else if (value === 2) {
            this.btnLoading = false;
          }
          this.$router.push("/console/directOverseas/myInquiry");
        }
      } else {
        //新增
        params.status = value;
        params.creator = this.USER_ID;
        params.creator_company_id = this.USER_INFO.company_id;
        params.recipient_id = this.inquiryUserId;
        params.recipient_company_id = this.inquiryCompanyId;
        params.recipient_email = this.inquiryUserEmail;
        params.inquiry_source = 3;
        let res = await this.$store.dispatch(
          "baseStore/consignorOffer",
          params
        );
        if (res.success) {
          if (value === 0) {
            this.draftLoading = false;
          } else if (value === 2) {
            this.btnLoading = false;
          }
          this.$router.push("/console/directOverseas/myInquiry");
        }
      }
    },
    selectFileListUpload(val) {
      if (this.uploadLength.length >= 3) {
        return;
      }
      let obj = {
        file_name: getFilesName(val[0].name),
        file_type: getenclosureType(getFileType(val[0].name)),
        deleted: 0,
      };
      this.selectUploadFile = obj;
      this.$refs.uploadComponents.uploadFileToOss();
    },
    uploadFieCallback(val) {
      this.selectUploadFile.url = val.url;
      this.inquiryForm.uploadList.push(this.selectUploadFile);
    },
    deleteFile(ee) {
      let current = this.inquiryForm.uploadList.findIndex(
        (item) => item === ee
      );
      if (current !== -1) {
        this.inquiryForm.uploadList[current].deleted = 1;
      }
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped lang="less">
.en_maoyi_width {
  width: 200px !important;
}
.mr30 {
  margin-right: 30px;
}

.mr12 {
  margin-right: 12px;
}

.ml60 {
  margin-left: 35px;
}

.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}

.ml16 {
  margin-left: 16px;
}

.ml24 {
  margin-left: 24px;
}

.ml12 {
  margin-left: 12px;
}

.publish-wrap {
  padding-bottom: 60px;
  .info-title {
    font-size: 18px;
    font-weight: bold;
    color: #1f292e;
    padding: 24px 0;
  }

  .info-content {
    padding: 0 80px;

    .demo-ruleForm {
      .label {
        width: 135px;
        text-align: left;
        // .name{
        //   position: relative;
        // }
        position: relative;

        .heart {
          position: absolute;
          width: 6px;
          height: 6px;
          background: #ff4949;
          border-radius: 50%;
          box-shadow: 0px 1px 2px 0px rgba(233, 59, 61, 0.2);
        }
      }

      .star {
        padding: 0 9px;
      }

      /deep/ .el-form-item {
        margin-bottom: 0 !important;
      }

      .cityCountry {
        display: flex;
        align-items: center;
      }

      .product {
        display: flex;
        align-items: center;

        .label {
          width: 78px;
          text-align: left;
        }
      }

      .package {
        display: flex;
        align-items: center;
      }

      .quantity {
        display: flex;
        align-items: center;

        .optional {
          margin-left: 24px;
          color: #298df8;
          font-size: 14px;
          font-weight: 400;
        }

        .numadd {
          padding: 0 7px;
        }
      }

      .weight {
        display: flex;
        align-items: center;
      }

      .volume {
        display: flex;
        align-items: center;

        .optional {
          margin-left: 24px;
          color: #298df8;
          font-size: 14px;
          font-weight: 400;
        }
      }

      .tradeTerms {
        display: flex;
        align-items: center;
      }

      .dangerous {
        display: flex;
        align-items: center;
      }

      .remark {
        display: flex;
        .showwordlimit {
          position: relative;
          /deep/ .el-input__count {
            position: absolute;
            right: -1px;
            bottom: -40px;
          }
        }
        .upload-btn {
          margin-bottom: 5px;
          .uploadInformation {
            position: relative;
            top: -6px;
          }
        }
        .annex {
          .item {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            .filename {
              width: 200px;
            }
            .el-icon-close {
              margin-left: 10px;
              &:hover {
                cursor: pointer;
                border-radius: 50%;
                overflow: hidden;
                background-color: #ccc;
              }
            }
          }
        }
      }
    }
  }

  .content_area {
    .backTitle {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 21px;
      font-weight: 400;
      color: #1f292e;
      padding: 24px 0;

      .icon-wrap {
        width: 20px;
        height: 20px;
        border: 1px solid #7d8695;
        border-radius: 50%;
        margin-right: 16px;
        cursor: pointer;

        i {
          font-size: 14px;
          position: relative;
          top: -7px;
        }
      }
    }

    .inquiryInfo {
      background: #ffffff;
      padding-bottom: 64px;
    }

    .accountInfo {
      margin-top: 16px;
      background: #ffffff;
      padding-bottom: 32px;

      .company {
        display: flex;
        align-items: center;
      }

      .contact {
        display: flex;
        align-items: center;
      }
    }

    .btns {
      margin-top: 32px;
      display: flex;
      justify-content: center;

      .draft {
        border: 1px solid #ff5155;
        color: #ff5155;
      }

      .cancel {
        background: #c8cdd0;
        color: #eeeeee;
        border: none;
      }

      .confirm {
        background: linear-gradient(90deg, #ff9966 0%, #ff5e62 100%);
        border: none;
      }
    }
  }
}
</style>
